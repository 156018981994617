<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Consider the balanced equation shown below:</p>

      <p class="mb-5 pl-14">
        <stemble-latex
          :content="`$\\ce{A(g) + ${molB.toInt()}B(g) -> ${molC}C(g) + ${molD.toInt()}D(g)}$`"
        />
      </p>

      <p class="mb-5">
        If the <b>rate of consumption</b> of reagent
        <stemble-latex content="$\text{A}$" />
        is found to be
        <number-value :value="rateA" unit="\text{M/s,}" />
        determine the <b>relative rate of consumption</b>
        of reagent
        <stemble-latex content="$\text{B}$" />
        as well as the <b>rate of formation</b>
        for products
        <stemble-latex content="$\text{C}$" />
        and
        <stemble-latex content="$\text{D.}$" />
      </p>

      <calculation-input
        v-model="inputs.rateB"
        class="mb-3"
        prepend-text="$\text{Rate of consumption of B:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.rateC"
        class="mb-3"
        prepend-text="$\text{Rate of formation of C:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.rateD"
        class="mb-2"
        prepend-text="$\text{Rate of formation of D:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question364',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rateB: null,
        rateC: null,
        rateD: null,
      },
    };
  },
  computed: {
    molB() {
      return this.taskState.getValueBySymbol('molB').content;
    },
    coefC() {
      // Could be 1
      return this.taskState.getValueBySymbol('molC').content;
    },
    molC() {
      if (this.coefC.toInt() === 1) {
        return '';
      } else {
        return this.coefC.toInt();
      }
    },
    molD() {
      return this.taskState.getValueBySymbol('molD').content;
    },
    rateA() {
      return this.taskState.getValueBySymbol('rateA').content;
    },
  },
};
</script>
